import en from "./en.json";
import zh from "./zh.json";
import my from "./my.json";
import th from "./th.json";
import id from "./id.json";

const resources = {
    en: {
        translation: en
    },
    zh: {
        translation: zh
    },
    my: {
        translation: my
    },
    th: {
        translation: th
    },
    id: {
        translation: id
    }
};

export default resources;
