import {useRef, useEffect, useState, useMemo} from "react";
import {LayoutRef} from "./components/Layout/Layout";

/**
 * 使用白色底色的标题栏
 */
export function useWhite() {
    const layoutRef = useRef<LayoutRef>(null);
    useEffect(() => {
        const logoDom = layoutRef?.current?.logoDom;  //logo(PC、移动)
        const languageMobileDom = layoutRef?.current?.languageMobileDom;  //多语下拉框（移动端）
        const menuDom = layoutRef?.current?.menuDom;  //导航文字（PC）
        const languageDom = layoutRef?.current?.languageDom;  //小地球（PC）
        const menuLogoDom = layoutRef?.current?.menuLogoDom;  //导航图标（移动端）
        logoDom && (logoDom.style.height = "0");  //logo
        menuDom && (menuDom.style.height = "0");  //导航文字
        languageDom && (languageDom.style.height = "0");  //小地球
        languageMobileDom && (languageMobileDom.style.height = "0");  //多语下拉框（移动端）
        menuLogoDom && (menuLogoDom.style.height = "0");  //导航图标（移动端）
    }, []);
    return [layoutRef];
}

/*
* PC系统判断
* */
export function useSystem() {
    const [isWindows, setIsWindows] = useState<boolean>(true);
    useEffect(() => {
        const agent = navigator.userAgent.toLowerCase();
        const isMac = /macintosh|mac os x/i.test(navigator.userAgent);
        if (agent.indexOf("win32") >= 0 || agent.indexOf("wow32") >= 0) {
            setIsWindows(true);
        }
        if (agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0) {
            setIsWindows(true);
        }
        if (isMac) {
            setIsWindows(false);
        }
    }, []);
    return [isWindows];
}

/**
 * 小窗口判断（宽度小于800）
 */
export function useSmallWindow() {
    const isMobile = useMemo(() => {
        return window.innerWidth <= 800;
    }, [window.innerWidth]);
    return [isMobile];
}

/**
 * 移动端系统判断
 */
export function useAndroid() {
    const [isAndroid, setIsAndroid] = useState(true);
    useEffect(() => {
        let u = navigator.userAgent;
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (isIOS) setIsAndroid(false);
    }, []);
    return [isAndroid];
}

/*系统语言判断*/
export function useLanguage() {
    const [language, setLanguage] = useState<string>("en");

    useEffect(() => {
        const language = window?.navigator?.language;  //获取系统语言
        if (language.includes("zh")) {  //中文（分3种）
            if (language.match(/cn|CN/)) {  //简体
                setLanguage("zh-CN");
            } else if (language.match(/tw|TW/)) {  //繁体
                setLanguage("zh-TW");
            } else if (language.match(/hk|HK/)) {
                setLanguage("zh-HK");
            } else {
                setLanguage("zh-CN");
            }
        } else if (language.includes("en")) {  //英文
            setLanguage("en");
        } else if (language.includes("ms")) {  //马来语
            setLanguage("ms");
        } else if (language.includes("th")) {  //泰语
            setLanguage("th");
        } else if (language.includes("id")) {  //印尼语
            setLanguage("id");
        } else {
            setLanguage("en");  //英语
        }
    }, []);

    return [language];
}

/*将系统语言转化为官网支持的语言（）*/
export function useChangeOsLanguage() {
    const [language, setLanguage] = useState<string>("en");

    useEffect(() => {
        const language = window?.navigator?.language;  //获取系统语言
        if (language.includes("zh")) {  //中文（分3种）
            setLanguage("zh");  //繁体中文
        } else if (language.includes("en")) {  //英文
            setLanguage("en");
        } else if (language.includes("ms")) {  //马来语
            setLanguage("my");
        } else if (language.includes("th")) {  //泰语
            setLanguage("th");
        } else if (language.includes("id")) {  //印尼语
            setLanguage("id");
        } else {
            setLanguage("en");  //英语
        }
    }, []);

    return [language];
}

/*判断是否为移动端*/
export function useMobile() {
    const [isMobile, setMobile] = useState<boolean>(false);
    useEffect(() => {
        if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) && window.matchMedia("only screen and (max-width:767px)").matches) {
            setMobile(true)
        } else (
            setMobile(false)
        )
    }, [navigator.userAgent])
    return [isMobile]
}