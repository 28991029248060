import React, {useEffect, useImperativeHandle, useRef, useState} from "react";
import styleObj from "./Header.module.less";
import {Link, NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useMobile} from "../../hooks";

type Props = {
    headerStyle?: object
}

export type HeaderRef = {
    logoDom: HTMLDivElement | null,
    menuDom: HTMLDivElement | null,
    languageDom: HTMLDivElement | null,
    languageMobileDom: HTMLDivElement | null,
    menuLogoDom: HTMLDivElement | null,
}

const Header = React.forwardRef<HeaderRef, Props>(({headerStyle = {}}, ref) => {
    const [showPay] = useMobile();  //展示支付入口
    const {t, i18n: {changeLanguage}} = useTranslation();
    const headerDiv = useRef<HTMLDivElement | null>(null);  //整个标题栏
    const logoDom = useRef<HTMLDivElement | null>(null);  //logo(PC、移动)
    const menuDom = useRef<HTMLDivElement | null>(null);  //导航文字（PC）
    const languageDom = useRef<HTMLDivElement | null>(null);  //小地球（PC）
    const languageBoxDom = useRef<HTMLDivElement | null>(null);  //多语选择框（PC）
    const languageMobileDom = useRef<HTMLDivElement | null>(null);  //多语下拉框（移动端）
    const menuLogoDom = useRef<HTMLDivElement | null>(null);  //导航图标（移动端）
    const menuBoxDom = useRef<HTMLDivElement | null>(null);  //导航box（移动端）
    const menuBoxLanguageDom = useRef<HTMLDivElement | null>(null);  //导航box中的多语切换开关（移动端）
    const languageDropdownLogoDom = useRef<HTMLDivElement | null>(null);  //导航box中的多语选择box后的小三角（移动端）
    const menuBoxLanguageDropdownLogoDom = useRef<HTMLDivElement | null>(null);  //导航box中的多语选择box后的小三角（移动端）

    const [showLanguage, setShowLanguage] = useState<boolean>(false);  //多语展示标志位（PC小地球）
    const [showLanguageMobile, setShowLanguageMobile] = useState<boolean>(false);  //多语展示标志位（移动下拉框）
    const [showMenu, setShowMenu] = useState<boolean>(false);  //移动端导航展示标志位
    const [showMenuBoxLanguageBoxDom, setShowMenuBoxLanguageBoxDom] = useState<boolean>(false);  //移动端全屏导航栏中的多语选择框展示标志位
    const [showFeaturesChild, setShowFeaturesChild] = useState<boolean>(false);  //展示 features 的子导航
    const [showFeaturesChildMobile, setShowFeaturesChildMobile] = useState<boolean>(false);  //展示 features 的子导航(移动端)

    const [languageBoxLeft, setLanguageBoxLeft] = useState<number>(50);
    const [featureChildLeft, setFeatureChildLeft] = useState<number>(800);

    useImperativeHandle(ref, () => ({
        logoDom: logoDom.current,  //logo(PC、移动)
        menuDom: menuDom.current,  //导航文字（PC）
        languageDom: languageDom.current,  //小地球（PC）
        languageMobileDom: languageMobileDom.current,  //多语下拉框（移动端）
        menuLogoDom: menuLogoDom.current,  //导航图标（移动端）
        headerDiv: headerDiv.current  //整个标题栏
    }));

    useEffect(() => {
        //多语点击事件
        document.addEventListener("click", e => {
            const target = e.target as HTMLDivElement;
            if (target?.className?.includes(styleObj.language)) {  //PC端多语
                setShowLanguage(value => !value);
            } else if (
                target?.className?.includes(styleObj.language_dropdown) ||
                target?.className?.includes(styleObj.language_dropdown_logo)
            ) {  //移动端多语
                if (!languageDropdownLogoDom.current) return;
                const clientRect = languageDropdownLogoDom.current.getBoundingClientRect();
                const {left: targetLeft} = clientRect;
                setLanguageBoxLeft(targetLeft - 78);  //需要控制多语弹窗位置
                setShowLanguageMobile(value => !value);
            } else if (  //移动端导航
                target?.className?.includes(styleObj.menu_dropdown_img) ||
                target?.className?.includes(styleObj.menu_dropdown)
            ) {
                setShowMenu(value => !value);
            } else if (
                target?.className?.includes(styleObj.menu_box_language_dropdown) ||
                target?.className?.includes(styleObj.menu_box_language_dropdown_logo)
            ) {
                //点击移动端全屏导航的多语切换
                if (!menuBoxLanguageDropdownLogoDom.current) return;
                const clientRect = menuBoxLanguageDropdownLogoDom.current.getBoundingClientRect();
                const {left: targetLeft} = clientRect;
                setLanguageBoxLeft(targetLeft - 78);  //需要控制多语弹窗位置
                setShowMenuBoxLanguageBoxDom(value => !value);
            } else {
                setTimeout(() => {  //防止多语还没点击就消失
                    setShowLanguage(false);
                    setShowLanguageMobile(false);
                    setShowMenuBoxLanguageBoxDom(false);
                });
            }
        }, true);
        return () => {
            document.onclick = null;
        };
    }, []);

    let timeout: any;
    const handleOverFeatures = ({target}: { target: HTMLDivElement }): void => {
        const {left, width} = target.getBoundingClientRect();
        setFeatureChildLeft(left + 0.5 * width);
        timeout && clearTimeout(timeout);
        setShowFeaturesChild(true);
    };

    const handleOutFeatures = (): void => {
        timeout = setTimeout(() => {
            setShowFeaturesChild(false);
        }, 350);
    };

    const handleOverFeaturesChild = (): void => {
        timeout && clearTimeout(timeout);
        setShowFeaturesChild(true);
    };

    const handleOutFeaturesChild = (): void => {
        setShowFeaturesChild(false);
    };

    return (
        <div
            style={headerStyle}
            className={`${styleObj.header} animate__animated`}
            ref={headerDiv}
        >
            {/*白色内容，变色时候把白色内容拉起来即可（理解为拉窗帘）*/}
            <div className={styleObj.content_wrapper}>
                <div className={styleObj.content}>
                    {
                        !showPay &&
                        <div
                            ref={logoDom}
                            className={styleObj.logo}> {/*左上角的logo*/}
                            <Link to="/">
                                <img
                                    src="https://auto.omicdn.com/v1/images/eyJpZCI6IkQ2SjNQM1pVRDdDTk1HN1ZZR05NRlhJR1hONTdEUSIsInciOjEyNCwiaCI6NjAsImQiOjAsIm10IjoiaW1hZ2UvanBlZyIsImRoIjoxMjE5MDU3ODI0MTk1MjEwODg2NCwiYWIiOjB9?format=originalOFGHLERTH"
                                    alt=""
                                />
                            </Link>
                        </div>
                    }

                    <div
                        ref={menuDom}
                        className={styleObj.menu}
                    >
                        <NavLink
                            to="/features"
                            exact
                            className={styleObj.link}
                            activeClassName={styleObj.selected}
                            onMouseOver={handleOverFeatures}
                            onMouseOut={handleOutFeatures}
                        >
                            {t("home.features")}
                        </NavLink>
                        <NavLink
                            to="/why"
                            exact
                            className={styleObj.link}
                            activeClassName={styleObj.selected}
                        >
                            {t("home.whyOmi")}
                        </NavLink>
                        <NavLink
                            to="/press"
                            exact
                            className={styleObj.link}
                            activeClassName={styleObj.selected}
                        >
                            {t("home.press")}
                        </NavLink>
                        <NavLink
                            to="/support"
                            exact
                            className={styleObj.link}
                            activeClassName={styleObj.selected}
                        >
                            {t("home.support")}
                        </NavLink>
                    </div>
                    <div
                        className={styleObj.features_child}
                        style={{left: featureChildLeft, display: showFeaturesChild ? "" : "none"}}
                        onMouseOver={handleOverFeaturesChild}
                        onMouseOut={handleOutFeaturesChild}
                    >
                        <NavLink
                            to="/features/toolkit"
                            exact
                            className={styleObj.link}
                            activeClassName={styleObj.selected}
                        >
                            {t("header.toolkit")}
                        </NavLink>
                        <NavLink
                            to="/features/subscription"
                            exact
                            className={styleObj.link}
                            activeClassName={styleObj.selected}
                        >
                            {t("header.subscription")}
                        </NavLink>
                    </div>
                    <div
                        ref={languageDom}
                        className={`${styleObj.language} ${showLanguage ? styleObj.clicked : ""}`}
                    >
                        {t("header.currentLanguage")}
                    </div>
                    {
                        showLanguage &&
                        <div
                            ref={languageBoxDom}
                            className={styleObj.language_box}
                        >
                            <span onClick={
                                () => {
                                    changeLanguage("zh");
                                    window.localStorage.setItem("lan", "zh");
                                }
                            }>
                                繁體中文
                            </span>
                            <span onClick={
                                () => {
                                    changeLanguage("en");
                                    window.localStorage.setItem("lan", "en");
                                }
                            }>ENGLISH</span>
                            <span onClick={
                                () => {
                                    changeLanguage("my");
                                    window.localStorage.setItem("lan", "my");
                                }
                            }>BAHASA MELAYU</span>
                            <span onClick={
                                () => {
                                    changeLanguage("th");
                                    window.localStorage.setItem("lan", "th");
                                }
                            }>ภาษาไทย</span>
                            <span onClick={
                                () => {
                                    changeLanguage("id");
                                    window.localStorage.setItem("lan", "id");
                                }
                            }>BAHASA INDONESIA</span>
                        </div>
                    }

                    {/*移动端多语切换开关*/}
                    <div
                        ref={languageMobileDom}
                        className={styleObj.language_dropdown}
                    >
                        {t("header.currentLanguage")}
                        <span
                            ref={languageDropdownLogoDom}
                            className={styleObj.language_dropdown_logo}
                        />
                    </div>

                    {/*移动端下拉多语*/}
                    {
                        showLanguageMobile &&
                        <div
                            className={styleObj.language_dropdown_box}
                            style={{left: languageBoxLeft}}
                        >
                            <span onClick={() => {
                                changeLanguage("zh");
                                window.localStorage.setItem("lan", "zh");
                            }}>繁體中文</span>
                            <span onClick={() => {
                                changeLanguage("en");
                                window.localStorage.setItem("lan", "en");
                            }}>ENGLISH</span>
                            <span onClick={() => {
                                changeLanguage("my");
                                window.localStorage.setItem("lan", "my");
                            }}>BAHASA MELAYU</span>
                            <span onClick={() => {
                                changeLanguage("th");
                                window.localStorage.setItem("lan", "th");
                            }}>ภาษาไทย</span>
                            <span onClick={() => {
                                changeLanguage("id");
                                window.localStorage.setItem("lan", "id");
                            }}>BAHASA INDONESIA</span>
                        </div>
                    }

                    {/*移动端导航开关*/}
                    <div
                        ref={menuLogoDom}
                        className={styleObj.menu_dropdown}
                    >
                        <img
                            className={styleObj.menu_dropdown_img}
                            src="https://auto.omicdn.com/v1/images/eyJpZCI6IktQVE5IS05RMko3MkxJQjZYUFBRQjNTSEJNTkhWTyIsInciOjQwLCJoIjo0MCwiZCI6MCwibXQiOiJpbWFnZS9qcGVnIiwiZGgiOjE5NDk3NjM5Njk5ODkxNDU2LCJhYiI6MH0?format=originalOFGHLERTH"
                            alt=""/>
                    </div>

                    {/*移动端下拉导航点击后展示的全屏导航栏*/}
                    {
                        showMenu &&
                        <div
                            ref={menuBoxDom}
                            className={styleObj.menu_box}
                        >
                            <div className={styleObj.menu_box_header}>
                                <div className={styleObj.menu_box_content}>
                                    {/*logo*/}
                                    <div
                                        ref={logoDom}
                                        className={styleObj.menu_box_logo}>
                                        <Link to="/">
                                            <img
                                                src="https://auto.omicdn.com/v1/images/eyJpZCI6IkQ2SjNQM1pVRDdDTk1HN1ZZR05NRlhJR1hONTdEUSIsInciOjEyNCwiaCI6NjAsImQiOjAsIm10IjoiaW1hZ2UvanBlZyIsImRoIjoxMjE5MDU3ODI0MTk1MjEwODg2NCwiYWIiOjB9?format=originalOFGHLERTH"
                                                alt=""
                                            />
                                        </Link>
                                    </div>

                                    {/*全屏导航：移动端多语切换开关*/}
                                    <div
                                        ref={menuBoxLanguageDom}
                                        className={styleObj.menu_box_language_dropdown}
                                    >
                                        {t("header.currentLanguage")}
                                        <span
                                            ref={menuBoxLanguageDropdownLogoDom}
                                            className={styleObj.menu_box_language_dropdown_logo}
                                        />
                                    </div>

                                    {/*全屏导航：关闭按钮*/}
                                    <div
                                        className={styleObj.menu_box_close}
                                        onClick={() => setShowMenu(false)}
                                    >
                                        <img
                                            src="https://auto.omicdn.com/v1/images/eyJpZCI6IjdVRkJWMjIyN1FWUVQ1SVNPQlI0QUozTUFGWjNIUiIsInciOjM2LCJoIjozNCwiZCI6MCwibXQiOiJpbWFnZS9qcGVnIiwiZGgiOjc1OTc5MTAxODE4MTE1NDg1MjEsImFiIjowfQ?format=originalOFGHLERTH"
                                            alt=""/>
                                    </div>

                                    {/*全屏导航：移动端下拉多语*/}
                                    {
                                        showMenuBoxLanguageBoxDom &&
                                        <div
                                            className={styleObj.menu_box_language_dropdown_box}
                                            style={{left: languageBoxLeft}}
                                        >
                                            <span onClick={
                                                () => {
                                                    changeLanguage("zh");
                                                    window.localStorage.setItem("lan", "zh");
                                                }
                                            }>繁體中文</span>
                                            <span onClick={() => {
                                                changeLanguage("en");
                                                window.localStorage.setItem("lan", "en");
                                            }}>ENGLISH</span>
                                            <span onClick={() => {
                                                changeLanguage("my");
                                                window.localStorage.setItem("lan", "my");
                                            }}>BAHASA MELAYU</span>
                                            <span onClick={() => {
                                                changeLanguage("th");
                                                window.localStorage.setItem("lan", "th");
                                            }}>ภาษาไทย</span>
                                            <span onClick={() => {
                                                changeLanguage("id");
                                                window.localStorage.setItem("lan", "id");
                                            }}>BAHASA INDONESIA</span>
                                        </div>
                                    }
                                </div>
                                <div className={styleObj.menu_box_header_line}/>
                            </div>
                            <div className={styleObj.menu_box_body}>
                                <div className={styleObj.features_wrapper}>
                                    <NavLink
                                        to="/features"
                                        exact
                                        className={styleObj.link}
                                        activeClassName={styleObj.selected}
                                        onClick={() => setShowMenu(false)}
                                    >
                                        {t("home.features")}
                                    </NavLink>
                                    <img
                                        src="https://auto.omicdn.com/v1/images/eyJpZCI6IjI0UEJHU1Q0QTJVVE5XQlZEUENaRExOWDI1NURNSiIsInciOjI0LCJoIjoxMiwiZCI6MCwibXQiOiJpbWFnZS9qcGVnIiwiZGgiOjg4NjM4MDczOTQ1ODQzMzM0OSwiYWIiOjB9?format=originalOFGHLERTH"
                                        className={styleObj.features_flag}
                                        alt=""
                                        onClick={() => setShowFeaturesChildMobile(val => !val)}
                                        style={showFeaturesChildMobile ? {transform: "rotate(180deg)"} : {}}
                                    />
                                </div>
                                {/*移动端的 features 子导航*/}
                                <div
                                    className={styleObj.features_child}
                                    style={{display: showFeaturesChildMobile ? "" : "none"}}
                                >
                                    <NavLink
                                        to="/features/toolkit"
                                        exact
                                        className={styleObj.link}
                                        activeClassName={styleObj.selected}
                                    >
                                        {t("header.toolkit")}
                                    </NavLink>
                                    <NavLink
                                        to="/features/subscription"
                                        exact
                                        className={styleObj.link}
                                        activeClassName={styleObj.selected}
                                    >
                                        {t("header.subscription")}
                                    </NavLink>
                                </div>
                                <NavLink
                                    to="/why"
                                    exact
                                    className={styleObj.link}
                                    activeClassName={styleObj.selected}
                                    onClick={() => setShowMenu(false)}
                                >
                                    {t("home.whyOmi")}
                                </NavLink>
                                <NavLink
                                    to="/press"
                                    exact
                                    className={styleObj.link}
                                    activeClassName={styleObj.selected}
                                    onClick={() => setShowMenu(false)}
                                >
                                    {t("home.press")}
                                </NavLink>
                                <NavLink
                                    to="/support"
                                    exact
                                    className={styleObj.link}
                                    activeClassName={styleObj.selected}
                                    onClick={() => setShowMenu(false)}
                                >
                                    {t("home.support")}
                                </NavLink>
                            </div>
                        </div>
                    }
                </div>
            </div>

            {/*反色内容*/}
            <div className={styleObj.content_wrapper}>
                <div className={`${styleObj.content} ${styleObj.inverse_color}`}>

                    {/*反色：PC端Omi*/}
                    {
                        !showPay &&
                        <div className={styleObj.logo}>
                            <Link to="/">
                                <img
                                    src="https://auto.omicdn.com/v1/images/eyJpZCI6IkQ2SjNQM1pVRDdDTk1HN1ZZR05NRlhJR1hONTdEUSIsInciOjEyNCwiaCI6NjAsImQiOjAsIm10IjoiaW1hZ2UvanBlZyIsImRoIjoxMjE5MDU3ODI0MTk1MjEwODg2NCwiYWIiOjB9?format=originalOFGHLERTH"
                                    alt=""
                                />
                            </Link>
                        </div>
                    }

                    {/*反色：PC端导航*/}
                    <div className={styleObj.menu}>
                        <NavLink
                            to="/features"
                            exact
                            className={styleObj.link}
                            activeClassName={styleObj.selected}
                            onMouseOver={handleOverFeatures}
                            onMouseOut={handleOutFeatures}
                        >
                            {t("home.features")}
                        </NavLink>
                        <NavLink
                            to="/why"
                            exact
                            className={styleObj.link}
                            activeClassName={styleObj.selected}
                        >
                            {t("home.whyOmi")}
                        </NavLink>
                        <NavLink
                            to="/press"
                            exact
                            className={styleObj.link}
                            activeClassName={styleObj.selected}
                        >
                            {t("home.press")}
                        </NavLink>
                        <NavLink
                            to="/support"
                            exact
                            className={styleObj.link}
                            activeClassName={styleObj.selected}
                        >
                            {t("home.support")}
                        </NavLink>
                    </div>

                    {/*反色：PC端地球*/}
                    <div
                        className={`${styleObj.language} ${showLanguage ? styleObj.clicked : ""}`}
                    >
                        {t("header.currentLanguage")}
                    </div>

                    {/*反色：移动端多语切换开关*/}
                    <div
                        className={styleObj.language_dropdown}
                    >
                        {t("header.currentLanguage")}
                        <span className={styleObj.language_dropdown_logo}/>
                    </div>

                    {/*反色：移动端下拉多语*/}
                    {
                        showLanguageMobile &&
                        <div
                            className={styleObj.language_dropdown_box}
                            style={{left: languageBoxLeft}}
                        >
                            <span onClick={() => {
                                changeLanguage("zh");
                                window.localStorage.setItem("lan", "zh");
                            }}>繁體中文</span>
                            <span onClick={
                                () => {
                                    changeLanguage("en");
                                    window.localStorage.setItem("lan", "en");
                                }
                            }>ENGLISH</span>
                            <span onClick={
                                () => {
                                    changeLanguage("my");
                                    window.localStorage.setItem("lan", "my");
                                }
                            }>BAHASA MELAYU</span>
                            <span onClick={
                                () => {
                                    changeLanguage("th");
                                    window.localStorage.setItem("lan", "th");
                                }
                            }>ภาษาไทย</span>
                            <span onClick={
                                () => {
                                    changeLanguage("id");
                                    window.localStorage.setItem("lan", "id");
                                }
                            }>BAHASA INDONESIA</span>
                        </div>
                    }

                    {/*反色：移动端导航开关*/}
                    <div
                        className={styleObj.menu_dropdown}
                    >
                        <img
                            className={styleObj.menu_dropdown_img}
                            src="https://auto.omicdn.com/v1/images/eyJpZCI6IlQySDZBVzNTWVdONUlQSVQ0TjNKU0FMMk1LRFVLRyIsInciOjQwLCJoIjo0MCwiZCI6MCwibXQiOiJpbWFnZS9qcGVnIiwiZGgiOjE4MzcxNzM5NzkzMDQ3ODA4LCJhYiI6MH0?format=originalOFGHLERTH"
                            alt=""/>
                    </div>

                </div>
            </div>
            <div className={styleObj.line}/>
        </div>
    );
});

export default Header;
