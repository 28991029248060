import React, { createContext, useContext, useState, useCallback, ReactNode } from 'react';
import s from './style.module.less';

// eslint-disable-next-line no-unused-vars
const ToastContext = createContext((a: any, b?: any) => {});

export const useToast = () => useContext(ToastContext);

export const ToastProvider = ({ children }: {children: ReactNode}) => {
  const [toast, setToast] = useState({ message: '', duration: 0 });

  const showToast = useCallback((message:string, duration?: number) => {
    const time = duration || 3000;
    setToast({ message, duration: time });
    setTimeout(() => setToast({ message: '', duration: 0 }), time);
  }, []);

  return (
    <ToastContext.Provider value={showToast}>
      {children}
      {toast.message && <div className={s.toast}>{toast.message}</div>}
    </ToastContext.Provider>
  );
};
