import React, {FC, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import styleObj from "./home.module.less";
import Layout, {LayoutRef} from "../../../components/Layout/Layout";
import Footer from "../../../components/Footer/Footer";
import Download from "../../../components/Download/Download";
import {useChangeOsLanguage} from "../../../hooks";
import {useHistory, useLocation} from "react-router-dom";
import {getApi, getSearchParams, postApi, sendPoint} from "../../../util/tool";
import Img from "../../../components/Img";
import {PAY_CARD} from "../../../const";

const imgArr: Array<string> = [
    "https://auto.omicdn.com/v1/images/eyJpZCI6IkhXRkJRQkdDQ1pBVjNQTUxBUFk0NkpPRUlMU1BBMiIsInciOjI4ODAsImgiOjE1ODAsImQiOjAsIm10IjoiaW1hZ2UvanBlZyIsImRoIjoxMTg2NTUyNjkwOTg1NTcwODMwMSwiYWIiOjB9?format=originalOFGHLERTH",
    "https://auto.omicdn.com/v1/images/eyJpZCI6Ik9USUE0V0pRR1E2MzdMQ1JQT05FTjVZN1ZDUVgyWSIsInciOjY1MCwiaCI6MjM0LCJkIjowLCJtdCI6ImltYWdlL2pwZWciLCJkaCI6NDk5MTM5NzE1OTAzNjU4NDI2MSwiYWIiOjB9?format=originalOFGHLERTH",
    "https://auto.omicdn.com/v1/images/eyJpZCI6IjNSTUNDRVU1Q01INFJGSFBERUpBWlVSNU1PTEJFNyIsInciOjY1MCwiaCI6NTMzLCJkIjowLCJtdCI6ImltYWdlL2pwZWciLCJkaCI6NDk3MjMzMTYyNzQxMDk0ODU0OCwiYWIiOjB9?format=originalOFGHLERTH"
];

const vipObj: any = {
    vip: 0,
    seeWhoLikedMe: 1,
    supreme: 2,
}

const Home: FC = () => {
    /*进入官网首页的时候，将系统语言同步到选择的语言*/
    const {t, i18n: {changeLanguage}} = useTranslation();
    const [oSLanguage] = useChangeOsLanguage()
    useEffect(() => {
        changeLanguage(oSLanguage).then()
        window.localStorage.setItem("lan", oSLanguage);
    }, [oSLanguage])

    const history = useHistory()
    const location = useLocation();
    const {hash} = location
    const {location: {search}} = useHistory()
    const [showModal, setShowModal] = useState(false)  //展示接受礼品的弹窗
    const [from, setFrom] = useState("")  //跳转来源(暂时用不到)
    const [currencyCode, setCurrencyCode] = useState("")  //货币符号
    const [loading, setLoading] = useState(false)  //登录加载中
    const [vipType, setVipType] = useState<Array<number>>([])  //会员种类 0 1 2
    const [logged, setLogged] = useState(false)  //用户已登录
    const layoutRef = useRef<LayoutRef>(null);
    const centerDiv = useRef<HTMLDivElement | null>(null);
    const homeDiv = useRef<HTMLDivElement | null>(null);
    const processArr = [
        useRef<HTMLSpanElement | null>(null),
        useRef<HTMLSpanElement | null>(null),
        useRef<HTMLSpanElement | null>(null)
    ];
    const processUnderArr = [
        useRef<HTMLSpanElement | null>(null),
        useRef<HTMLSpanElement | null>(null),
        useRef<HTMLSpanElement | null>(null)
    ];
    const processWrapper = useRef<HTMLDivElement | null>(null);
    const contentArr = [
        useRef<HTMLDivElement | null>(null),
        useRef<HTMLDivElement | null>(null),
        useRef<HTMLDivElement | null>(null)
    ];
    const [showIndex, setShowIndex] = useState<number>(-1);
    const [start, setStart] = useState<boolean>(false);
    const [backgroundColor, setBackgroundColor] = useState<string>("");
    const cardData = [
        {
            cardContent: t("home.cardContent1"),
            cardUser: t("home.cardUser1")
        },
        {
            cardContent: t("home.cardContent2"),
            cardUser: t("home.cardUser2")
        },
        {
            cardContent: t("home.cardContent3"),
            cardUser: t("home.cardUser3")
        }
    ];

    const initData = async () => {
        const searchParams = getSearchParams(search)
        const {token: authToken = "", currencyCode = "", from, canRedeemed} = searchParams
        if (from === "iosbanner" && canRedeemed === "true") {
            setFrom("iosBanner")
            setShowModal(true)
            setTimeout(() => {
                sendPoint({event_name: "omi_phone_website_free_crush_popup.pageview"})
            }, 10000)
        }
        // 如果有URL带来的currencyCode（货币代码），则存入本地
        if (currencyCode) {
            localStorage.setItem("currencyCode", currencyCode)
            setCurrencyCode(currencyCode)
        }
        // 如果有URL带来的authToken，则用其换取accessToken
        if (authToken) {  //如果从App跳转过来
            try {
                const res1 = await postApi("/web/access-token", {authToken})
                if (res1?.accessToken) {
                    localStorage.setItem("accessToken", res1.accessToken)  //存到本地
                    history.push("/")  //url上的token，用掉后立即干掉
                }
            } catch (e) {
                console.log(e)
            }
        } else {  //直接进入官网或干掉token后进入官网，以 localStorage 中的 accessToken 为主
            await init()
        }
    }

    const init = async () => {
        try {
            const res2 = await getApi("/web/privileges")
            if (res2.meta.code === 401000) {  //未登录
                setLogged(false)
                setLoading(false)
            } else {  //登录了
                /*设置登录状态*/
                setLogged(true)
                setLoading(false)
                /*设置会员信息*/
                if (res2?.data?.simplePrivileges && Array.isArray(res2?.data?.simplePrivileges)) {
                    const resVipType = res2.data.simplePrivileges.map((item: string) => vipObj[item])
                    setVipType(resVipType)
                } else {
                    setVipType([])
                }
                /*获取到会员信息后打点*/
                sendPoint({
                    event_name: `omi_phone_website_home_page.pageview`,
                    status: "logged_in"
                })
            }
        } catch (e) {
            setVipType([])
            setLogged(false)
        }
    }

    useEffect(() => {
        if (hash) {  //短信链接跳进来的
            history.push({
                pathname: "/vip",
                state: {
                    authToken: hash.substring(1)
                }
            })
        } else {
            initData()
        }
    }, [hash, search])

    const initDataAnimation = async () => {
        sendPoint({
            event_name: `omi_phone_website_home_page.pageview`,
            status: "un_logged_in"
        })
        await startAnimation();
    }

    useEffect(() => {
        imgArr.forEach(src => {
            const img = new Image();
            img.src = src;
        });
        initDataAnimation()
    }, [])

    useEffect(() => {
        const homeDom = homeDiv.current;  //用于绑定滚动事件
        const logoDom = layoutRef?.current?.logoDom!;  //logo(PC、移动)
        const languageMobileDom = layoutRef?.current?.languageMobileDom!;  // 多语下拉框（移动端）
        const menuLogoDom = layoutRef?.current?.menuLogoDom!;  //导航图标（移动端）

        const handler = () => {
            const centerDom = centerDiv.current!;
            if (centerDom) {
                const {top, bottom} = centerDom.getBoundingClientRect();
                //控制渐进反色
                if (top < 20) {
                    logoDom && (logoDom.style.height = "0");  //logo
                    languageMobileDom && (languageMobileDom.style.height = "0");  //多语下拉框（移动端）
                    menuLogoDom && (menuLogoDom.style.height = "0");  //导航图标（移动端）
                } else if (top > 42) {
                    logoDom && (logoDom.style.height = `${22}px`);
                    languageMobileDom && (languageMobileDom.style.height = `${28}px`);
                    menuLogoDom && (menuLogoDom.style.height = `${20}px`);
                } else {
                    logoDom && (logoDom.style.height = `${top - 20}px`);
                    languageMobileDom && (languageMobileDom.style.height = `${top - 20}px`);
                    menuLogoDom && (menuLogoDom.style.height = `${top - 20}px`);
                }

                //控制头部背景色渐变
                if (top >= 0 && top <= 56) {
                    setBackgroundColor(`rgba(255,255,255,${(56 - top) / 56})`);
                } else if (top < 0) {
                    setBackgroundColor("rgb(255,255,255)");
                } else {
                    setBackgroundColor("");
                }

                //控制动画开始
                if (bottom < 1.4 * window.innerHeight) {
                    setStart(true);
                }
            }
        };

        homeDom && homeDom.addEventListener("scroll", handler);  //绑定滚屏事件
        let timeout1: any, timeout2: any;
        if (showIndex !== -1) {
            showCenterContent(showIndex);
            if (showIndex >= 2) {
                timeout1 = setTimeout(() => {
                    setShowIndex(0);
                }, 4000);
            } else {
                timeout2 = setTimeout(() => {
                    setShowIndex(val => val + 1);
                }, 4000);
            }
        }

        return () => {
            homeDom && homeDom.removeEventListener("scroll", handler);
            clearTimeout(timeout1);
            clearTimeout(timeout2);
        };
    }, [showIndex]);

    useEffect(() => {
        if (start) {
            processWrapper.current!.style.display = "flex";
            processWrapper.current!.classList.add("animate__fadeInUpBig");
            setTimeout(() => {
                setShowIndex(0);
            }, 1000);
        }
    }, [start]);

    const showCenterContent = (targetIndex: number) => {
        contentArr.forEach((content, index) => {
            if (index === targetIndex) {
                content.current!.style.opacity = "1";
            } else {
                content.current!.style.opacity = "0";
            }
        });
        processArr.forEach((process, index) => {
            if (index === targetIndex) {
                processUnderArr[index].current!.style.backgroundColor = "#212121";
                process.current!.style.transition = "0s width";
                process.current!.style.width = "0";
                setTimeout(() => {
                    process.current!.style.transition = "3900ms width linear";
                    process.current!.style.width = "100%";
                }, 100);
            } else if (index > targetIndex) {
                processUnderArr[index].current!.style.backgroundColor = "#212121";
                process.current!.style.transition = "0s width";
                process.current!.style.width = "0";
            } else {
                process.current!.style.transition = "0s width";
                process.current!.style.width = "100%";
                processUnderArr[index].current!.style.backgroundColor = "#16ec9b";
            }
        });
    };

    const setAnimation = (dom: HTMLElement, className: string, delay: number = 0): void => {
        setTimeout(() => {
            dom.style.visibility = "visible";
            dom.classList.add(className);
        }, delay);
    };

    const startAnimation = (): Promise<void> => {
        return new Promise<void>(resolve => {
            setAnimation(layoutRef.current!.headerDiv!, "animate__fadeInDown");
            setTimeout(() => {
                resolve();
            }, 1100);
        });
    };

    const handleAccept = () => {
        try {
            postApi("/web/users/me/welfare", {id: "4", status: "accepted"})
        } catch (e) {
            console.log(e)
        }
        sendPoint({event_name: "omi_phone_website_free_crush_popup.click"})
        linkToVip({getPrize: true})
    }

    const linkToVip = (state: object = {}) => {
        if (vipType.includes(1) || vipType.includes(2)) {
            history.push({pathname: '/vip', state: {linkCurrentVip: 2, ...state}})
        } else {
            history.push({pathname: '/vip', state: {linkCurrentVip: 1, ...state}})
        }
    }

    const renderButton = () => {
        if (!logged) {  //如果未登录，展示的是登录按钮
            return (
                <div
                    onClick={
                        () => {
                            setLoading(true)
                            window.location.href = "omi://payauth";
                            setTimeout(function () {
                                setLoading(false)
                            }, 1500);
                        }
                    }
                >
                    <div className={styleObj.button_inner}>
                        {t("h5.OMI_PHONE_WEBSITE_HOME_PAGE_LOGIN")}
                        <div className={styleObj.save1}>
                            {t("h5.OMI_WEBSITE_HOME_PAGE_LOWEST_PRICE")}
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div
                    className={styleObj.button_one}
                    onClick={() => linkToVip()}
                >
                    {t("h5.OMI_PHONE_WEBSITE_HOME_PAGE_CONTINUE")}
                    <div className={styleObj.save1}>
                       {t("h5.OMI_WEBSITE_HOME_PAGE_LOWEST_PRICE")}
                    </div>
                </div>
            )
        }
    }

    return (
        <Layout
            ref={layoutRef}
            headerStyle={{backgroundColor, visibility: "hidden"}}
            isHome={true}
        >
            <div
                ref={homeDiv}
                className={styleObj.home}
                style={{height: window.innerHeight}}
            >
                {/*第一屏*/}
                <div className={styleObj.top}>
                    <Img className={styleObj.big_omi}
                         src="https://auto.omicdn.com/v1/images/eyJpZCI6IkpBUDRMQVBKWlVLMlVPM0dCUDdLTFVGUjVRSUdaRiIsInciOjI1NCwiaCI6MTEyLCJkIjowLCJtdCI6ImltYWdlL2pwZWciLCJkaCI6MTUzMDA3OTIwNDQ5MDQzMzM3ODYsImFiIjowfQ"/>

                    <div className={styleObj.button_wrapper}>
                        {
                            loading ?
                                <div className={styleObj.button_loading}>
                                    <img
                                        className={styleObj.loading_img}
                                        src="https://auto.omicdn.com/v1/images/eyJpZCI6IkVYNVhKWEM2WDY1WU5NRkU1NE9BRE1BWTZMSE5RQSIsInciOjUyLCJoIjo1MiwiZCI6MCwibXQiOiJpbWFnZS9qcGVnIiwiZGgiOjUxODE5NzQ0NTY2Mjg4ODUwNywiYWIiOjB9?format=originalOFGHLERTH"
                                        alt=""
                                    />
                                    {t("h5.OMI_PHONE_WEBSITE_HOME_PAGE_LOGINING")}
                                </div>
                                :
                                <div onClick={
                                    () => {
                                        sendPoint({
                                            event_name: `omi_phone_website_home_page_button.click`,
                                            status: logged ? "logged_in" : "un_logged_in"
                                        })
                                    }
                                }
                                >
                                    {renderButton()}
                                </div>
                        }
                        <div className={styleObj.button_title}>
                            {t("home.OMI_PHONE_WEBSITE_HOME_PAGE_PURCHASE_MORE_METHOD")}
                        </div>
                        <div className={styleObj.pay_card_wrapper}>
                            {
                                logged && Array.isArray(PAY_CARD[(currencyCode || localStorage.getItem("currencyCode")!)]) &&
                                PAY_CARD[(currencyCode || localStorage.getItem("currencyCode")!)].map((src: any) => <Img
                                    className={styleObj.pay_card} src={src}/>)
                            }
                        </div>
                    </div>
                </div>

                {/*第二屏*/}
                <div
                    className={styleObj.center}
                    ref={centerDiv}
                >
                    <div className={styleObj.content}>
                        <div className={styleObj.text_wrapper}>
                            {
                                cardData.map(({cardContent, cardUser}, index) => (
                                    <div key={index} ref={contentArr[index]}>
                                        <div>
                                            <img
                                                src="https://auto.omicdn.com/v1/images/eyJpZCI6IkJYWUtRVzNVSzZNNUlQSjNIVllMNlhONVNYSFRKUCIsInciOjEyOCwiaCI6MTM2LCJkIjowLCJtdCI6ImltYWdlL2pwZWciLCJkaCI6MTM4NDg1NjAyNjU0NjE3NjAsImFiIjowfQ?format=originalOFGHLERTH"
                                                alt=""/>
                                        </div>
                                        <div>{cardContent}</div>
                                        <div>{cardUser}</div>
                                    </div>
                                ))
                            }
                        </div>
                        <div
                            ref={processWrapper}
                            className={`${styleObj.process_wrapper} animate__animated`}
                        >
                            {
                                Array.from({length: 3}).map((_, index) => (
                                        <div
                                            key={index}
                                            onClick={() => setShowIndex(index)}
                                        >
                                            <span ref={processUnderArr[index]}/>
                                            <span ref={processArr[index]}/>
                                        </div>
                                    )
                                )
                            }
                        </div>
                    </div>
                </div>

                {/*第三屏*/}
                <Download/>
                <Footer/>

                {/*弹窗*/}
                {
                    showModal &&
                    <div className={styleObj.cover_div}>
                        <div className={styleObj.modal}>
                            <div className={styleObj.text1}>{t("home.OMI_PHONE_WEBSITE_FREE_CRUSH_TITLE")}</div>
                            <div className={styleObj.text2}>{t("home.OMI_PHONE_WEBSITE_FREE_CRUSH_SUBTITLE")}</div>
                            <Img
                                className={styleObj.modal_img}
                                src="https://auto.omicdn.com/v1/images/eyJpZCI6IkZBSE5QNjU3V1FVM0lYN1kyR1JUSFpRSEtCVkY3RCIsInciOjQyNiwiaCI6MzIyLCJkIjowLCJtdCI6ImltYWdlL2pwZWciLCJkaCI6MzA0ODE3NTc1MTM1MTg5OTQyNSwiYWIiOjB9"
                            />
                            <div className={styleObj.text3}>Crush X 3</div>
                            <div className={styleObj.text4} onClick={handleAccept}>
                                {t("home.OMI_PHONE_WEBSITE_FREE_CRUSH_BUTTON")}
                            </div>
                            <Img
                                onClick={() => setShowModal(false)}
                                className={styleObj.modal_close}
                                src="https://auto.omicdn.com/v1/images/eyJpZCI6IlNMRlhLVk40RjJQTldMVExHWjNLUFM1VjNZQ1FRNyIsInciOjQ4LCJoIjo0OCwiZCI6MCwibXQiOiJpbWFnZS9qcGVnIiwiZGgiOjE0NTEwNjAyMjY4MDE0NzYzNzIsImFiIjowfQ"
                            />
                        </div>
                    </div>
                }
            </div>
        </Layout>
    );
};

export default Home;