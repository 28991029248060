import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import resources from "./locales/resources";

const lan = window.localStorage.getItem("lan");

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: lan || "en",
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
