import React from 'react';

type ImageProps = React.ImgHTMLAttributes<HTMLImageElement>;

const Img: React.FC<ImageProps> = (props) => {
    const imgProps = {
        ...props,
        src: props.src + "?format=originalOFGHLERTH"
    }
    return <img {...imgProps} />;
};

export default Img;
