export const PERMISSION_PRY_TYPE = ['TWD', 'IDR', 'THB', 'MYR'];

export const ESPECIAL_PAY_TYPE = ['IDR', 'TWD', 'HKD', 'THB', 'RUB']; // 这几种货币特殊计算

export const PAY_CARD: any = {
  TWD: [
    'https://auto.omicdn.com/v1/images/eyJpZCI6IldDV0RFUEJZQlNISldNSEw0SEFTTVBTWkg2QktBNiIsInciOjE5MiwiaCI6NzIsImQiOjAsIm10IjoiaW1hZ2UvanBlZyIsImRoIjo2MTgwODQ5NTgzNjM0NTUyNDA1fQ',
    'https://auto.omicdn.com/v1/images/eyJpZCI6IlBKMjRNMzJZN1JEWEYzT1RWNU5IU0RYWE9WMlRNRCIsInciOjE5MiwiaCI6NzIsImQiOjAsIm10IjoiaW1hZ2UvanBlZyIsImRoIjo1ODgyMzEwOTM5NTUyNDk0MTY1fQ',
    'https://auto.omicdn.com/v1/images/eyJpZCI6Ik1HTjVZQktDVEY3NUM3RVY3WVM2REszTEdDV09GQSIsInciOjE5MiwiaCI6NzIsImQiOjAsIm10IjoiaW1hZ2UvanBlZyIsImRoIjo1MDAzNjE5NDUxMTU0NTU3MDEzfQ',
  ],
  IDR: [
    'https://auto.omicdn.com/v1/images/eyJpZCI6IjVSREkyWUw2UFRRSVMyWEJRU0haWUJCSDYzVEVRWiIsInciOjE5MiwiaCI6NzIsImQiOjAsIm10IjoiaW1hZ2UvanBlZyIsImRoIjo2MTgwODQ5NTgzNjM0NTUyNDA1fQ',
    'https://auto.omicdn.com/v1/images/eyJpZCI6Ikg0U05UNjVBNlEzWEtCQlI3V1lMSFhUQlFLUUNGRCIsInciOjE5MiwiaCI6NzIsImQiOjAsIm10IjoiaW1hZ2UvanBlZyIsImRoIjo1NjA3NTk0OTEyMzcxMjgyNTA5fQ',
    'https://auto.omicdn.com/v1/images/eyJpZCI6IktMNDdBSVhMSFdaS0xMTFVFSEJWMlVVWVNDNExWTyIsInciOjE5MiwiaCI6NzIsImQiOjAsIm10IjoiaW1hZ2UvanBlZyIsImRoIjo1MDAzNjE5NDUxMTU0NTU3MDEzfQ',
  ],
  MYR: [
    'https://auto.omicdn.com/v1/images/eyJpZCI6IkRST01UUUFKWTZDRUFOTkxIM09YTDRFN1RBNUdLTSIsInciOjE5MiwiaCI6NzIsImQiOjAsIm10IjoiaW1hZ2UvanBlZyIsImRoIjo2MTgwODQ5NTgzNjM0NTUyNDA1fQ',
    'https://auto.omicdn.com/v1/images/eyJpZCI6Ik5RVjUzRzZQQ01KQ0FJN1NVN0tFMkVTSUdOTTIzWSIsInciOjE5MiwiaCI6NzIsImQiOjAsIm10IjoiaW1hZ2UvanBlZyIsImRoIjo4MTkyNzM1MzA5NDk4MjY0MTc3fQ',
    'https://auto.omicdn.com/v1/images/eyJpZCI6IkdINjZVN1lENEhBMlMzWFBDWk9HQkZFTVZaRkQyUCIsInciOjE5MiwiaCI6NzIsImQiOjAsIm10IjoiaW1hZ2UvanBlZyIsImRoIjo1MDAzNjE5NDUxMTU0NTU3MDEzfQ',
  ],
  THB: [
    'https://auto.omicdn.com/v1/images/eyJpZCI6Ik83WFhEWExZWkhLRTJMVTJQQVE0S0pSUkZLRUY2UCIsInciOjE5MiwiaCI6NzIsImQiOjAsIm10IjoiaW1hZ2UvanBlZyIsImRoIjo2MTgwODQ5NTgzNjM0NTUyNDA1fQ',
    'https://auto.omicdn.com/v1/images/eyJpZCI6IjNNWFRSV1lUV0lLUlA3TzZNWUFXT0EyTUVRWk1HRCIsInciOjE5MiwiaCI6NzIsImQiOjAsIm10IjoiaW1hZ2UvanBlZyIsImRoIjo1NTg5NjUwNDAxNTQ5MjU3MDQ1fQ',
    'https://auto.omicdn.com/v1/images/eyJpZCI6IkFaM0tMTk5ETFdFWFlRN05YRzRDTlBDRU5HQkFQTSIsInciOjE5MiwiaCI6NzIsImQiOjAsIm10IjoiaW1hZ2UvanBlZyIsImRoIjo1MDAzNjE5NDUxMTU0NTU3MDEzfQ',
  ],
};
