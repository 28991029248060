import React, {FC} from "react";
import styleObj from "./Footer.module.less";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {useSmallWindow} from "../../hooks";

const Footer: FC = () => {
    const [isMobile] = useSmallWindow();
    const {t, i18n: {language}} = useTranslation();
    return (
        <div className={styleObj.footer}>
            <div className={styleObj.line}/>
            <div className={styleObj.content}>
                <div className={styleObj.left}>
                    <a
                        target="_blank"
                        href={`/privacy/${language}`}
                    > {t("home.privacy")} </a>
                    {
                        !isMobile ?
                            <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                            :
                            <span>&nbsp;|&nbsp;</span>
                    }
                    <a
                        target="_blank"
                        href={`/terms/${language}`}
                    > {t("home.terms")} </a>
                    {
                        !isMobile ?
                            <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                            :
                            <span>&nbsp;|&nbsp;</span>
                    }
                    <a
                        target="_blank"
                        href="https://omiapp.onelink.me/HCKs/g7zp1mmz"
                    > {t("home.download")} </a>
                    {
                        !isMobile ?
                            <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                            :
                            <span>&nbsp;|&nbsp;</span>
                    }
                    <a
                        target="_blank"
                        href={`/norms/${language}`}
                    > {t("home.guidelines")} </a>
                    {
                        !isMobile ?
                            <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                            :
                            <span>&nbsp;|&nbsp;</span>
                    }
                    <a
                        target="_blank"
                        href="/help"
                    > {t("home.help")} </a>
                    {
                        !isMobile ?
                            <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                            :
                            <span>&nbsp;|&nbsp;</span>
                    }
                    <Link
                        to="/support"
                    > {t("home.contact")} </Link>
                </div>
                <div className={styleObj.right}>
                    {t("home.copyright")}
                </div>
            </div>
        </div>
    );
};

export default Footer;
