import React from 'react';
import ReactDOM from 'react-dom/client';
import 'antd/dist/antd.variable.min.css';
import 'animate.css';
import './index.less';
import './i18n'
import App from './App';
import { ConfigProvider } from 'antd';

ConfigProvider.config({
    theme: {
        primaryColor: '#25b864',
    },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<App/>);
