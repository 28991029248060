import React from "react";
import {useSmallWindow} from "../../hooks";
import Home1 from './mobile/Home'
import Home2 from './pc/Home'

export default () => {
    const [isMobile] = useSmallWindow();  //用于判断屏幕宽度
    if (isMobile) {
        return <Home1/>
    } else {
        return <Home2/>
    }
}